import React from 'react';
import { PathService } from '../../services';
import {
  FindARiskAdvisorDirectorViewContext,
  TemplateProps,
} from '../../types';
import { FindARiskAdvisorDirectorView } from '../../views';

const FindARiskAdvisorDirector: React.FunctionComponent<
  TemplateProps<FindARiskAdvisorDirectorViewContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <FindARiskAdvisorDirectorView
      content={JSON.parse(pageContext.content)}
      director={JSON.parse(pageContext.director)}
      region={JSON.parse(pageContext.region)}
      directorsWithRegions={JSON.parse(pageContext.directorsWithRegions)}
      languageSwitch={JSON.parse(pageContext.languageSwitch)}
    />
  );
};

export default FindARiskAdvisorDirector;
